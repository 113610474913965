import React,{useEffect, useState} from 'react'
import { Link/*, Script, ScriptStrategy*/, graphql, navigate } from "gatsby"

import axios from 'axios';
import Layout from "./../../components/layout"
import ThemeContext from "./../../context/ThemeContext"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import useWindowSize from './../../hooks/useGatsbyWindowSize'
import { useMediaQuery } from 'react-responsive'
import SwiperCore, { Virtual, Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide, useSwiperSlide  } from 'swiper/react'
import {gsap} from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { CustomEase } from "gsap/CustomEase"
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import SvgEl from './../../components/SvgEl'
import LOGO from './../../svg/logos'

SwiperCore.use([Virtual, Navigation, Pagination, Autoplay]);


gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);
gsap.config({autoKillThreshold: 1});


const EventSinglePage = ({ data, location }) => {


  const [jsonImage, setJsonImage] = useState('');
  const [jsonContent, setJsonContent] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasGallery, setHasGallery] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [swiperRef, setSwiperRef] = useState(null);
  const [title, setTitle] = useState(false);

  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
  const {width,height} = useWindowSize();
    var device = '';


    const swiperSlide = useSwiperSlide();

  // Create array with slides
  const [slides, setSlides] = useState(
    Array.from({ length: 1 }).map((_, index) =>  <div className="firstSlide"></div>)
  );


  function loadHtml(url) {
  var _json = url;
  axios.get(_json)
.then(function (response) {

  //console.log(response.data.data);



  var _jsonContent = response.data.data.map( (item, index) => {


    var _jsonImage = item.image;
    setJsonImage(_jsonImage);
    var date_init = new Date(item.date_init);
    var date_end = new Date(item.date_end);

    const datesAreOnSameDay = (date_init, date_end) =>
      date_init.getFullYear() === date_end.getFullYear() &&
      date_init.getMonth() === date_end.getMonth() &&
      date_init.getDate() === date_end.getDate();

      var eventDateInit = date_init.toLocaleDateString('en-US', {
    /*timeZone: 'America/Chicago',*/
    hour: '2-digit',
    minute: '2-digit',
  });
  var eventDateEnd = date_end.toLocaleDateString('en-US', {
/*timeZone: 'America/Chicago',*/
hour: '2-digit',
minute: '2-digit',
});

let pm = date_end.getHours() >= 12;
let hour12 = date_end.getHours() % 12;
if (!hour12)
  hour12 += 12;
let minute = date_end.getMinutes();
var eventSameDateEnd = `${hour12}:${minute} ${pm ? 'PM' : 'AM'}`;

//var eventSameDateEnd = date_end.getHours() + ':' + date_end.getMinutes();
if(datesAreOnSameDay){
  eventDateEnd = eventSameDateEnd;
}



      setTitle(item.name);

      return (
        <div key={'d_' + item.id} className="row">

        <div className="col-sm-12 col-md-12">


        <div className="pt-0 pl-5 pr-5 text-center">

        <div className="text-center pt-0 pb-3">
        {item.isnext == 'next' ?
        <a className="btn btn-light btn-secondary btn-small m-1" href={"https://discover.lilleymansion.com/cal/"+ item.id}><small>Save the date</small></a>
        : ''
      }
      {item.isnext == 'next' ?
      <a className="btn btn-secondary btn-small m-1" href={item.reservation_url}><small>Book this event</small></a>
      : ''
    }
        </div>


        <p className="text-center event-info">
        {eventDateInit} - {eventDateEnd}<br/>
        {item.location}</p>

        <div className="description-blog text-left mt-3" dangerouslySetInnerHTML={{ __html: item.description}}/>



        </div>



       </div>
       <p>&nbsp;</p>
       </div>
     );

   });

setJsonContent(_jsonContent);

  //device = response.data.device;
  response.data.data[0].gallery.length > 0 ? setHasGallery(true) : setHasGallery(false) ;
 var newArr = response.data.data[0].gallery.map(firstSlides);
 //console.log(newArr);
 var t= setTimeout(function(){
   setSlides(newArr);
   clearTimeout(t);
 },1200);

//  var _jsonContent =  response.data.content;

  setIsLoaded(true);
});
}
function firstSlides(img){

  var css_pos = '50% 0px';
  var _params = ['#',50];
  var url = '/suites' ;
  var btnLabel = 'DISCOVER OUR SUITES';

  if(img.desc && mobile){

     _params = img.desc.split('#');
    // console.log(_params);
    if (_params.length > 2){
       css_pos =  _params[1] + '% 0px';
       url = _params[3] ? _params[3] : url;
       btnLabel = _params[2] ? _params[2] : btnLabel;
    }

  }

   return (
     <div className="slider-image-full" data-pos={_params[1] ? _params[1] : 50} style={{background:'url('+img.gallery.url+')',backgroundPosition:css_pos}}>
     <div className="slide-logo slide-logo-small">
     <SvgEl svg={LOGO.MAIN} className='logo-bg animate__animated animate__zoomFadeIn animate__delay-3s'/>
     </div>
     <div className="featured animate__animated animate__fadeInRight animate__delay-1s">
  {/*  <h3 className="ttl">Title</h3> */}
  {_params.length > 2 ? <a href={url} className="actionBtn btn btn-dark">{btnLabel}</a> : ''}

     </div>

     </div>

  );
}

function slideEffect(){



};



  useEffect(() => {
    setIsMobile(mobile);
    var _hash = location.hash.split('/');
    console.log(_hash)
    if(_hash && _hash.length > 1){
      loadHtml('https://discover.lilleymansion.com/api/v1/events/'+ _hash[1]);
    }else{
      window.location.href= '/events';
    }
  },[]);


  return (

    <ThemeContext.Consumer>
        {theme => (
    <div id="page" className="pt-5">

  { hasGallery ?  <Swiper
    className={"swiper-full has-gallery-" + hasGallery}
    autoplay={{
          delay: 5500,
          disableOnInteraction: true,
        }}
    onSwiper={(e)=>{
      setSwiperRef(e);
    }}
    onSlideChange={(swiper) =>  {
      var slds = swiper.slides;
    //  console.log(slds);
      var sldsActiveChild = slds[1].children[0];
      //var featuredActive =  slds[0].children[0].firstChild;
      var sldsPrevChild = slds[0].children[0];
      //var featuredPrev =  slds[1].children[0].firstChild;

      if(slds.length < 3){
        //gsap.to([featuredPrev,featuredActive],0,{delay: 0, ease: 'Quad.easeOut', opacity: 0, bottom: '-600px' });
      gsap.to([sldsActiveChild],0,{delay: 0, ease: 'Quad.easeOut', backgroundPosition: '40% 0px' });

      //  var _pos = sldsActiveChild.dataset.pos > 0 ? sldsActiveChild.dataset.pos : 50;
        gsap.to([sldsActiveChild],2,{delay: 0, ease: 'Quad.easeOut', backgroundPosition: 50 + '% 0px' });
        //gsap.to([featuredPrev,featuredActive],.3,{delay: 0, ease: 'Quad.easeOut', opacity: 1, bottom: '100px' });

      }else if(slds.length > 2){

        var sldsPrevChild = slds[0].children[0];
        var sldsNextChild = slds[2].children[0];


      //    sldsNextChild.firstChild.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
      //  var featuredPrev =  slds[0].children[0].firstChild;
      //  var featuredNext =  slds[2].children[0].firstChild;

        //gsap.to([featuredPrev,featuredNext],0,{delay: 0, ease: 'Quad.easeOut', opacity: 0, bottom: '-600px' });
    }


      //console.log(swiper.touches.diff);
      //console.log(swiper.activeIndex > swiper.previousIndex);
      //var right = swiper.activeIndex > swiper.previousIndex;
      if(slds.length > 2){

      if(swiper.activeIndex > swiper.previousIndex){ // to the right
        sldsActiveChild.firstChild.classList.remove('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
        sldsNextChild.firstChild.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');


        var num_0 = parseInt(sldsNextChild.dataset.pos);
        var _pos_0 = num_0 > 0 ? sldsNextChild.dataset.pos : 50;
        var _posInit_0 = num_0 - 10;

        gsap.to([sldsNextChild],0,{delay: 0, ease: 'Quad.easeOut', backgroundPosition: _posInit_0 +'% 0px' });
        gsap.to(sldsNextChild,2,{delay: 0, ease: 'Quad.easeOut', backgroundPosition: _pos_0 + '% 0px' });
        //gsap.to(featuredNext,.3,{delay: .3, ease: 'Quad.easeOut', opacity: 1, bottom: '100px' });

      }else{
        sldsActiveChild.firstChild.classList.remove('animate__animated', 'animate__fadeIn', 'animate__delay-1s');
        sldsPrevChild.firstChild.classList.add('animate__animated', 'animate__fadeIn', 'animate__delay-1s');


        var num_1 = parseInt(sldsPrevChild.dataset.pos);
        var _pos_1 = num_1 > 0 ? sldsPrevChild.dataset.pos : 50;
        var _posInit_1 = num_1 + 10;

        gsap.to([sldsPrevChild],0,{delay: 0, ease: 'Quad.easeOut', backgroundPosition: _posInit_1 + '% 0px' });
        gsap.to(sldsPrevChild,2,{delay: 0, ease: 'Quad.easeOut', backgroundPosition: _pos_1 +'% 0px' });
      //  gsap.to(featuredPrev,.3,{delay: .3, ease: 'Quad.easeOut', opacity: 1, bottom: '100px' });

      }
      }

    }}
    slidesPerView={1}
    centeredSlides={true}
    spaceBetween={0}
    initialSlide={1}
    pagination={{
      type: 'fraction',
    }}
    navigation={true}
    virtual
  >
    {slides.map((slideContent, index) => (
      <SwiperSlide className="swiper-slide-full" key={'s'+index} virtualIndex={index}>
        {slideContent}
      </SwiperSlide>
    ))}
  </Swiper>
  :
  <div className="container text-center pt-5">
  <img width="100%" src={'https://discover.lilleymansion.com/storage/app/media' + jsonImage} alt='xx'/>
  </div>
}

    <div id="page-wrapper">


    <div className="container mt-1 mb-0 p-0 text-center">

    <h1 className="-titling">{title}</h1>

    <p className="text-center"><a className="backBtn mt-2" href="/events/events-at-lilley-mansion"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
</svg> BACK</a></p>

    </div>
    </div>







  {/*
    <div className="imageFullWidth">
  { isMobile && data.photos.edges.length > 1 ?

      <GatsbyImage
       fluid={data.photos.edges[1].node.childImageSharp.fluid}
       alt={data.photos.edges[1].node.base.split("-").join(" ").split(".")[0]}
       image={getImage(data.photos.edges[1].node)}
       />
       :
       <GatsbyImage
        fluid={data.photos.edges[0].node.childImageSharp.fluid}
        alt={data.photos.edges[0].node.base.split("-").join(" ").split(".")[0]}
        image={getImage(data.photos.edges[0].node)}
        />
    }


    </div>
    */}
    <div className="pt-0 p-4">
    {/* <div className="container pt-4 p-2" dangerouslySetInnerHTML={{ __html: jsonContent }} /> */}
    {jsonContent}

    </div>



    </div>

  )}
      </ThemeContext.Consumer>

  )
}

EventSinglePage.Layout = Layout

export default EventSinglePage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }

    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "home"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
  }
`
